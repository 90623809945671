.headerContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  color: #f6f7f8;

  justify-content: space-between;
  width: 100%;
  padding: 6px 0;
  height: 50px !important;
}

.headerText {
  font-variant: all-small-caps;
  font-size: xx-large;
}

.headerButton {
  align-items: center;
  background-color: black !important;
  border-radius: 10% !important;
  color: #161722 !important;
  cursor: pointer;
  display: flex !important;
  font-size: x-large !important;
  height: 38px !important;
  justify-content: center !important;
  letter-spacing: 0.2rem !important;
  width: 38px !important;
  margin: 0 !important;
  margin-left: 6px !important;
  border-bottom: 3px solid rgba(0, 0, 0, 0.1);
  color: white !important;
}

.headerSubtext {
  font-size: 2vh;
  color: #b1b3b4;
  font-variant: all-small-caps;
  margin-bottom: 16px;
}

.rightHeaderButton {
  margin-right: 6px !important;
}

.randomButton {
  background-color: #4c467a !important;
}

.shareButton {
  background-color: #e07b53 !important;
}

#donateButton {
  margin: 12px;
  width: 265px;
  font-size: 24px;
  height: 50px;
  padding: 0;
  font-family: "Roboto Mono", monospace !important;
}

.unlockButton {
  padding: 6px !important;
  font-size: 24px !important;
  height: 40px !important;
  font-family: "Roboto Mono", monospace !important;
}
