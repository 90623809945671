* {
  font-family: "Roboto Mono", monospace;
}

body {
  background-color: #1a1a1b;
  margin: 0;
  font-family: "Roboto Mono", sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  user-select: none;
}

.App {
  align-items: center;
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  max-width: 500px;
  text-align: center;
  height: var(--app-height);
  max-height: var(--app-height);
  background-color: #1a1a1b;
}

.ui.modal {
  top: 20% !important;
}

.boardButton {
  align-items: center;
  background-color: black !important;
  border-radius: 10% !important;
  color: #161722 !important;
  cursor: pointer;
  display: flex !important;
  font-size: xx-large !important;
  height: 100% !important;
  justify-content: center !important;
  letter-spacing: 0.2rem !important;
  width: 100% !important;
  margin: 0 !important;
  border-bottom: 3px solid rgba(0, 0, 0, 0.1);
  color: white !important;
}

.deleteButton {
  background-color: #b11a1a !important;
}

.checkButton {
  background-color: #2b2b2e !important;
}

.checkButton.ready {
  background-color: #3a8b38 !important;
}

.checkButtonCheckModal {
  width: 50px !important;
  height: 40px !important;
  margin-top: 12px !important;
}

.refreshButton {
  background-color: #b59626 !important;
}

.statsContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.statsRow {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-top: 10px;
}

.statsColumn {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.shareRow {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-top: 10px;
}

.shareColumn {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.shareButton {
  background-color: #3a8b38 !important;
  color: white !important;
  margin: 12px 0px !important;
}

.stars {
  display: flex !important;
  flex-direction: row !important;
  width: fit-content;
}

.badWords {
  display: flex;
  flex-direction: column;
  align-items: center;
}
