.square {
  width: 100%;
  height: 100%;
  position: relative;
  /* box-shadow: inset #e4e5f1 2px 2px; */
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #161722 !important;
  border-bottom: 3px solid rgba(0, 0, 0, 0.1);
}

.tile {
  width: 100%;
  height: 100%;
  position: relative;
  /* box-shadow: inset #e4e5f1 2px 2px; */
  border-radius: 10px;
  background-color: #515656 !important;
  color: white !important;
  border-bottom: 3px solid rgba(0, 0, 0, 0.1);
}

/* .vowel {
  background: #4b398b !important;
} */
