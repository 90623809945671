.helpModalContainer {
  font-variant: all-small-caps;
  padding: 24px;

  display: flex !important;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  background-color: #1a1a1b !important;
  color: white;
}

#helpModalContainer {
  display: flex !important;
  flex-direction: column !important;
  font-size: 20px;
  align-items: center;
}

#checkModalContainer {
  display: flex !important;
  flex-direction: column !important;
  font-size: 20px;
  align-items: center;
}

#helpModalContainer div {
  margin-bottom: 24px;
  text-align: center;
}

.helpModalTitle {
  margin: auto;
  margin-bottom: 12px;
  font-size: 24px;
  font-weight: bold;
}

.close.icon {
  color: white !important;
}
